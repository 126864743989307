import React from "react";

import RenderMarkdown from "./utilities/RenderMarkdown";
import ConditionalWrap from "./utilities/ConditionalWrap";
import TrackedLink from "./utilities/TrackedLink";
import PreviewCompatibleImage from "./utilities/PreviewCompatibleImage";
import BrandButton from "./BrandButton";

const ContentsList = ({
  contentsObject,
  classNameCard = "",
  classNameText = "text-center"
}) => {
  return (
    <div className="space-y-8 sm:space-y-14">
      <RenderMarkdown
        markdownContent={contentsObject.title}
        className={`px-5 ${classNameText}`}
      />
      <ul className="flex flex-col space-y-10 sm:space-y-16">
        {contentsObject.list.map((el) => {
          return (
            <li
              className={`flex flex-col sm:flex-row sm:space-x-5 sm:p-8 rounded-3xl ${classNameCard}`}
            >
              {el.imageObject && (
                <div className="mx-auto max-w-md">
                  <ConditionalWrap
                    condition={el.imageObject.linkUrl}
                    wrap={(children) => (
                      <TrackedLink href={el.imageObject.linkUrl}>
                        {children}
                      </TrackedLink>
                    )}
                  >
                    <PreviewCompatibleImage
                      imageObject={el.imageObject}
                      loading="lazy"
                      className="rounded-3xl mx-5 sm:mx-0 bg-white"
                    />
                  </ConditionalWrap>
                </div>
              )}

              <div
                className={`flex flex-col ${
                  el.imageObject ? "sm:w-3/5" : "w-full"
                }`}
              >
                <RenderMarkdown
                  markdownContent={el.description}
                  isMarkdown={true}
                  className="ml-0 p-5 pt-10 sm:p-0 bg-white sm:bg-inherit -mt-5 sm:mt-0"
                />
              </div>
            </li>
          );
        })}
      </ul>
      {contentsObject.ctaObject && (
        <div className="flex justify-center">
          <BrandButton href={contentsObject.ctaObject.linkUrl}>
            <RenderMarkdown
              markdownContent={contentsObject.ctaObject.linkText}
              whitespace="normal"
            />
          </BrandButton>
        </div>
      )}
    </div>
  );
};

export default ContentsList;

import React, { useState, useEffect } from "react";

import hasQueryParam from "../functions/hasQueryParam";

import Header from "../components/Header";
import Section from "../components/Section";
import PreviewCompatibleImage from "../components/utilities/PreviewCompatibleImage.js";
import TrackedLink from "../components/utilities/TrackedLink.js";
import RenderMarkdown from "./utilities/RenderMarkdown";

export default function HeaderWithLogin({ imageObject }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className={`${isVisible ? "" : "invisible"}`}>
      {hasQueryParam("nologin") || hasQueryParam("sei-dabei") ? (
        <div>
          <Header imageObject={imageObject} />
        </div>
      ) : (
        <header className="sm:px-10">
          <Section
            className="!py-0"
            classNameInnerContainer="flex justify-between"
          >
            <div className="max-w-xs  sm:px-5 font-normal landscape:h-[20vh] landscape:lg:h-[15vh] h-[15vh] md:h-[10vh] max-h-32">
              <PreviewCompatibleImage
                imageObject={imageObject}
                loading="eager"
                className="h-full mr-auto sm:scale-150"
                objectFit="contain"
              />
            </div>
            <div className="flex items-center">
              <TrackedLink
                href="https://www.achtsamkeitsacademy.de/login"
                className="text-[#000000]"
              >
                <RenderMarkdown
                  markdownContent={"#### LOGIN"}
                  whitespace="normal"
                />
              </TrackedLink>
            </div>
          </Section>
        </header>
      )}
    </div>
  );
}
